* {
	box-sizing: border-box;
}

html{
	box-sizing: border-box;
	min-height: 100%;
	position: relative;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;	
}

body {
	background-color: $white;
	color: $black;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	height: 100%;
	line-height: 26px;
	margin: 0;
	padding: 0;
	padding-top: $header-height;

	@include fontSmoothing;
}

img {
	height: auto;
	max-width: 100%;
}

h1, h2, h3, h4, h5 {
	line-height: 1.2;
	margin-bottom: 10px;
	margin-top: 0;
	position: relative;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 26px;
}

a {
	color: $black;
	text-decoration: none;

	&:hover {
		color: $red;
	}
}

p {
	margin-top: 0;
}

blockquote {
	border-left: 5px solid #333;
    margin: 0 0 5px 20px;
    padding: 0 0 0 16px;
    text-align: left;

    p {
	    font-style: italic;
	    font-weight: 400;
	    font-size: 24px;
	    margin-bottom: 0;
	}

	cite {
	    font-style: normal;
	    font-weight: 400;
	    font-size: 16px;
	    line-height: 1.125;
	    color: #999;
	    display: block;

	    &:before {
		    content: "\2014 ";
		}
	}
}

small {
    font-size: 80%;
}

button {
	cursor: pointer;
}

.padding {
	padding: 40px 0;
}

.overline {
	&:before {
		background-color: $red;
		content: '';
		height: 3px;
		position: absolute;
			left: 0;
			top: -5px;
		width: 49px;		
	}	
}

.underline {
	&:before {
		background-color: $red;
		content: '';
		height: 5px;
		position: absolute;
			left: 0;
			bottom: -15px;
		width: 115px;		
	}

	&.small {

		&:before {
			bottom: -10px;
			height: 3px;
			width: 49px;	
		}		
	}
}

.read-more {
	color: $red;
	display: inline-block;
	margin-bottom: 10px;
	padding-right: 20px;
	position: relative;

	&:before {
		border-style: solid;
		border-width: 6px 0 6px 10px;
		border-color: transparent transparent transparent $red;
		content: '';
		position: absolute;
			right: 0;
			top: 7px;
	}
}