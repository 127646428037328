.intro {
	background-position: top left;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: -$header-height;
	padding: 130px 0 30px;

	&__content {
		background-color:rgba(0,0,0,0.6);
		color: #fff;
		padding: 20px 30px;

		a {
			color: #fff;
		}
	}
}

.shop {
	background-color: #F3F5F6;
	padding: 30px;
	text-align: center;
}

.reviews {
	padding: 30px 0;
	text-align: center;
}